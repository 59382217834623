.questionMainTitle {
	font-size: 0.8rem;
	font-weight: 500;
	text-align: left;
}

.flexContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.optionContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.1rem 0.5rem;
	border-radius: 5px;
	margin: 0.1rem 0.5rem;
	border: 1px solid #000;
	min-width: 40%;
	text-align: center;
}

.blockContainer {
	border: 1px solid #6d6d6d;
	padding: 0.1rem 0.5rem;
}

.imageHookContainer {
	width: 50px;
	height: auto;
	border-color: #6d6d6d;
	border-radius: 3px;

	img {
		object-fit: cover;
	}
}
