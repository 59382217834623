.upload-player-container {
	display: flex;
	flex-direction: column;
	margin-top: 1.5rem;
}

.upload-item {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	background-color: #fff;
}

.upload-item-text {
	color: #9d9d9d;
}

.upload-text {
	font-size: 1rem;
	color: #333;
	flex: 1;
}

.upload-icon {
	width: 22px;
	height: 22px;
	cursor: pointer;
	transition: transform 0.2s ease;
}

.upload-icon:hover {
	transform: scale(1.1);
}

.tooltip-container {
	position: relative;
	display: inline-block;
	cursor: pointer;
}
