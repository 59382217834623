@mixin importantize($properties...) {
	@each $property, $value in $properties {
		#{$property}: #{$value} !important;
	}
}

.dimensionSelect {
	@include importantize(margin-bottom 20px);
}

.matrixContainer {
	@include importantize(display inline-block, border-width 1px, border-style solid, border-color #000, padding 5px, height fit-content);
}

.matrixRow {
	@include importantize(display flex, /* 'x' is not a valid CSS property; if you meant flex-direction, use flex-direction row or column */);
}

.matrixCell {
	@include importantize(
		width 40px,
		height 40px,
		margin 2px,
		text-align center,
		font-size 18px,
		border-width 1px,
		border-style solid,
		border-color #808080,
		box-sizing border-box,
		border-radius 5px
	);
}

.matrixCellFilled {
	@include importantize(
		width 40px,
		height 40px,
		margin 2px,
		text-align center,
		font-size 18px,
		border-width 1px,
		border-style solid,
		border-color #f49d9d,
		box-sizing border-box,
		background-color #ff89c4,
		border-radius 5px
	);
}

.extractedWords {
	@include importantize(display inline-block, width 100%, height 100%, margin-left 20px);

	h3 {
		@include importantize(margin-bottom 10px, font-size 40px);
	}

	& > ul {
		@include importantize(font-size 25px, color #858585);
	}
}

.extractedWords input[type="checkbox"] {
    width: 12px;
    height: 12px; 
    margin-right: 5px; 
}

.extractedWords label {
    display: flex;
    align-items: center;
    cursor: pointer; 
}

.extractedWords ul {
    list-style-type: none; 
    padding: 0; 
    margin: 0;
}

.extractedWords li {
    margin: 0; 
}

.formContainer {
  flex: 1;
  margin-left: 16px;

  h3 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 16px;
  }
}
