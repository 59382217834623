.overlay-container {
	position: relative;
}

.overlay-container.disabled-overlay::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	/* background: #c8c8c899; */
}
