.react-select {
	width: 100%;
	height: 40px;
	font-size: 12px;
}

.react-select__control {
	height: 32px;
	border-radius: 0 !important;
	transition: all 0.3s;

	@include themify($themes) {
		border: 1px solid themed('colorFieldsBorder');
		background-color: themed('colorBackground');
	}

	&.react-select__control--is-focused,
	&:hover {
		border-color: $color-accent !important;
		box-shadow: none;
		background: transparent;
	}
}

.react-select__input {
	height: 30px;
}

.react-select__indicator-separator {
	display: none;
}

.react-select__dropdown-indicator,
.react-select__clear-indicator {
	cursor: pointer;

	@include themify($themes) {
		color: themed('colorIcon');
	}

	svg {
		height: 16px;
		width: 16px;
	}
}

.react-select__multi-value {
	background-color: transparent;
	border: 1px solid $color-blue;

	.react-select__multi-value__label {
		@include directify($directions) {
			#{directed('border-right')}: 1px solid $color-blue;
		}
		padding: 3px 6px;

		@include themify($themes) {
			color: themed('colorText');
		}
	}
}

.react-select__multi-value__remove {
	opacity: 0.8;
	transition: 0.3s;
	cursor: pointer;

	@include themify($themes) {
		color: themed('colorText');
	}
}

.react-select__multi-value__label,
.react-select__multi-value__remove {
	@include themify($themes) {
		background: themed('colorBackground');
	}
}

.react-select__single-value {
	@include themify($themes) {
		color: themed('colorText');
	}
}

.react-select__menu {
	box-shadow: none !important;
	margin-top: 6px;
	margin-bottom: 6px;
}

.react-select__menu-list {
	top: calc(100% + 1px);
	border-radius: 0;
	box-shadow: none;
	font-size: 12px;
	animation: open 0.3s ease-in-out;
	overflow: hidden;

	@include themify($themes) {
		background: themed('colorBackground');
		border: 1px solid themed('colorFieldsBorder');
	}
}

@keyframes open {
	0% {
		max-height: 0;
	}
	100% {
		max-height: 200px;
	}
}

.react-select__option {
	transition: all 0.3s;
	border-radius: 0;
	display: flex;
	cursor: pointer;
	padding: 8px 10px;

	@include themify($themes) {
		background: themed('colorBackground');
		color: themed('colorText');
	}

	&.react-select__option--is-focused {
		@include themify($themes) {
			background: themed('colorHover');
		}
	}
}

.react-select__color {
	display: block;
	border-radius: 50%;
	height: 10px;
	width: 10px;
	@include directify($directions) {
		#{directed('margin-right')}: 0px;
		#{directed('margin-left')}: 5px;
	}
	margin-top: auto;
	margin-bottom: auto;
}

.form__select-color {
	display: flex;
	flex-wrap: wrap;
	max-width: 84px;
	@include directify($directions) {
		#{directed('margin-right')}: 40px;
	}
}
