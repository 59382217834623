.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000; /* Ensure it's on top of other content */
}

.modal-content {
	background-color: white;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	position: relative;
	max-width: 70vw;
	height: 90vh;
	overflow: scroll;
}

.modal-close-button {
	position: absolute;
	top: 3px;
	right: 3px;
	background: none;
	border: none;
	font-size: 20px;
	cursor: pointer;
}

.modal-close-button:hover {
	color: red; /* Change color on hover */
}
